import { useState } from "react";
import logo from "assets/images/pocketbook-logo.png";
import signup_logo from "assets/images/sign-up-bg.png";
import { StyledTextField } from "pages/signup/utils/styled-inputfield";
import {
  SignupWrapper,
  FormGroupSection,
  FormSubmitBtn,
  SignupLeftHeader,
  LeftSection,
  ChampionBgSection,
  PocketbookLogo,
  SignupRightImage,
  RightSectionTitle,
  SignupFormTitle,
  SignupFormDescription,
  ForgotFormSection,
} from "./styled";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [validated, setValidated] = useState(null);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [errMessage, setErrMessage] = useState("Email is required!");

  const validateEmail = (value) => {
    const validEmailRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (value === "") {
      setValidated(false);
      setErrMessage("Email is required!");
    } else if (!validEmailRegex.test(value)) {
      setValidated(false);
      setErrMessage("Email must be valid");
    } else {
      setValidated(true);
    }
  };

  const handleEmailChange = (event) => {
    setIsSubmitted(false);
    validateEmail(event.target.value);
    setEmail(event.target.value);
  };

  const onSubmit = async () => {
    setIsSubmitted(true);
    setValidated(validated === null ? false : validated);
    if (validated) {
      console.log("forgot email", email);
    }
  };

  return (
    <SignupWrapper>
      <LeftSection>
        <SignupLeftHeader>
          <PocketbookLogo src={logo} alt="logo" />
        </SignupLeftHeader>
        <ForgotFormSection>
          <div style={{ width: "100%" }}>
            <SignupFormTitle>Forgot your password?</SignupFormTitle>
            <SignupFormDescription>
              Input your email and we will send you a link to reset your
              password
            </SignupFormDescription>
            <FormGroupSection>
              <StyledTextField
                name="email"
                value={email}
                placeholder="Email"
                validation={validated}
                errMsg={errMessage}
                isSubmitted={isSubmitted}
                handleChange={handleEmailChange}
              />
              {validated ? (
                <FormSubmitBtn onClick={onSubmit}>Submit</FormSubmitBtn>
              ) : (
                <FormSubmitBtn className="opacity-5">Submit</FormSubmitBtn>
              )}
            </FormGroupSection>
          </div>
        </ForgotFormSection>
      </LeftSection>
      <ChampionBgSection>
        <SignupRightImage src={signup_logo} alt="signup-right-image" />
        <RightSectionTitle>
          It's time to save money and optimize your business
        </RightSectionTitle>
      </ChampionBgSection>
    </SignupWrapper>
  );
};

export default ForgotPassword;
