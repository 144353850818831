import React, { useState } from "react";
import { SignupFormTitle, TermsText } from "../styled";
import styled from "styled-components";
import { StyledTextField } from "../utils/styled-inputfield";
import { navigate } from "@reach/router";
import { onboardingRoutes } from "routes";
import { Checkbox } from "@mui/material";
import { checkSubDomain, createClient } from "../utils";
import CircularProgress from "@mui/material/CircularProgress";
import { useDispatch, useSelector } from "react-redux";
import { actionTypes } from "../../../redux/actionTypes";
import { ErrMsg } from "../styled";
import { Button } from "ui";

export const CustomizeUrl = () => {
  const dispatch = useDispatch();
  const { profileInfo } = useSelector((state) => state.profile);
  const [subDomain, setSubDomain] = useState("");
  const [validated, setValidated] = useState(false);
  const [errMessage, setErrMessage] = useState("");
  const [agreeTerms, setAgreeTerms] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const validationRegex = /^[a-z0-9]+$/;

  const handleSubDomainChange = (event) => {
    validateSubDomain(event.target.value);
    setSubDomain(event.target.value);
  };

  const regexValidation = (value) => {
    if (!validationRegex.test(value)) {
      setErrMessage(
        "Your Custom website name cannot contain capital letters, spaces or special characters."
      );
      setValidated(false);
    } else {
      setValidated(true);
      setErrMessage("");
    }
  };

  const validateSubDomain = async (value) => {
    if (value === "") {
      setErrMessage("Custom website name is required.");
      setValidated(false);
    } else {
      regexValidation(value);
    }
  };

  const handleCheckbox = (event) => {
    setAgreeTerms(event.target.checked);
  };

  const handleConfirmation = async () => {
    if (agreeTerms && validated) {
      setErrMessage("");
      setIsLoading(true);
      await checkSubDomain(subDomain + ".pocketbook.tech")
        .then((res) => {
          if (res.exists) {
            setErrMessage("Domain already exist");
            setValidated(false);
          } else {
            createClient({
              user_id: profileInfo.user_id,
              subdomain: res.subdomain,
            })
              .then(() => {
                navigate(onboardingRoutes.success);
              })
              .catch((error) => {
                console.log(error);
              });
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  return (
    <>
      <SignupFormTitle>Customize your Pocketbook</SignupFormTitle>
      <Description>
        You get to <b>create your own website</b> that your customers see when
        they receive invoices and contracts from you! We suggest keeping it
        simple by using your business name. It's a website, so no dashes or
        spaces are allowed.
      </Description>
      <Description>This is also how you will log into Pocketbook.</Description>
      <ContentWrapper>
        <StyledTextField
          name="subdomain"
          value={subDomain}
          validation={validated}
          placeholder="yourbusinessname"
          handleChange={handleSubDomainChange}
        />
        <StyledErrMsg>{errMessage}</StyledErrMsg>
        <div className="flex align-items-center">
          <Checkbox
            checked={agreeTerms}
            onChange={handleCheckbox}
            inputProps={{ "aria-label": "controlled" }}
            style={{ padding: 0, paddingRight: 8 }}
          />
          <TermsText style={{ color: agreeTerms ? "#000000" : "#939393" }}>
            I understand that my custom website name cannot be changed later.
          </TermsText>
        </div>
        <Button
          disabled={isLoading}
          variant="black"
          onClick={handleConfirmation}
          marginTop={40}
        >
          {isLoading ? (
            <CircularProgress size={12} color="inherit" />
          ) : (
            "Confirm"
          )}
        </Button>
      </ContentWrapper>
    </>
  );
};

const Description = styled.div`
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  margin-top: 11px;
`;

const ContentWrapper = styled.div`
  margin-top: 40px;
`;

const StyledErrMsg = styled(ErrMsg)`
  margin-bottom: 30px;
`;
