import { combineReducers } from "redux";
import profileReducer from "./profileReducer";
// import authReducer from "../pages/auth/reducer";

const rootReducer = combineReducers({
  profile: profileReducer,
  // auth: authReducer,
});

export default rootReducer;
