import { useState } from "react";
import { navigate } from "@reach/router";
import logo from "assets/images/pocketbook-logo.png";
import signup_logo from "assets/images/sign-up-bg.png";
import { StyledTextField } from "pages/signup/utils/styled-inputfield";
import { getPlatformSubdomain, login } from "./actions";
import {
  ContactBtn,
  FormGroupSection,
  FormSubmitBtn,
  LeftHeader,
  LeftSection,
  LoginFormDescription,
  LoginFormSection,
  LoginFormTitle,
  LoginLeftFooter,
  LoginWrapper,
  PocketbookLogo,
  RightImage,
  RightSection,
  RightSectionTitle,
} from "./styled";
import { toast } from "react-toastify";
import { CircularProgress } from "@mui/material";

const GenericLogin = () => {
  const [email, setEmail] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [validated, setValidated] = useState({
    email: null,
  });
  const [errMessage, setErrMessage] = useState({
    email: "Business Email is required!",
  });

  const validateEmail = (value) => {
    const validEmailRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (value === "") {
      setValidated((prevState) => ({ ...prevState, email: false }));
      setErrMessage((prevState) => ({
        ...prevState,
        email: "Business Email is required!",
      }));
    } else if (!validEmailRegex.test(value)) {
      setValidated((prevState) => ({ ...prevState, email: false }));
      setErrMessage((prevState) => ({
        ...prevState,
        email: "Business Email must be valid",
      }));
    } else {
      setValidated((prevState) => ({ ...prevState, email: true }));
    }
  };

  const handleEmailChange = (event) => {
    setIsSubmitted(false);
    validateEmail(event.target.value);
    setEmail(event.target.value);
  };

  const onSubmit = async () => {
    setIsSubmitted(true);
    setValidated({
      email: validated.email === null ? false : validated.email,
    });
    if (validated.email) {
      await getPlatformSubdomain(email).then((res) => {
        console.log("GET SUBDOMAIN RESPONSE::", res);
        if (res?.exists) {
          window.open(`https://${res.subdomain}/login?email=${email}`, "_self"); //_self
        } else {
          toast.error(`We couldn't find this email in our system.`);
          setIsSubmitted(false);
        }
      });
    }
  };

  return (
    <LoginWrapper>
      <LeftSection>
        <LeftHeader>
          <PocketbookLogo src={logo} alt="logo" />
        </LeftHeader>
        <LoginFormSection>
          <div style={{ width: "100%" }}>
            <LoginFormTitle>Login</LoginFormTitle>
            <LoginFormDescription>
              Welcome back! Please enter your email address below to proceed.
            </LoginFormDescription>
            <FormGroupSection>
              <StyledTextField
                name="businessEmail"
                value={email}
                placeholder="Business Email"
                validation={validated.email}
                errMsg={errMessage.email}
                isSubmitted={isSubmitted}
                handleChange={handleEmailChange}
              />

              <FormSubmitBtn onClick={onSubmit} disabled={isSubmitted}>
                {isSubmitted ? (
                  <CircularProgress size={20} sx={{ color: "white" }} />
                ) : (
                  "Submit"
                )}
              </FormSubmitBtn>
            </FormGroupSection>
          </div>
        </LoginFormSection>
        <LoginLeftFooter>
          <ContactBtn>
            Don't have an account?{" "}
            <span
              className="text-underline"
              onClick={() => navigate("/signup")}
            >
              Create your Pocketbook account today.
            </span>
          </ContactBtn>
        </LoginLeftFooter>
      </LeftSection>
      <RightSection>
        <RightImage src={signup_logo} alt="signup-right-image" />
        <RightSectionTitle>
          It's time to save money and optimize your business
        </RightSectionTitle>
      </RightSection>
    </LoginWrapper>
  );
};

export default GenericLogin;
