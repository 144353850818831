import React from 'react'
import { OnboardingLayout, PlaidConnection } from './components'
import { PlaidFooter } from './components'

export const ConnectBank=()=>{
  return (
    <OnboardingLayout footer={<PlaidFooter/>}>
        <PlaidConnection/>
    </OnboardingLayout>
  )
}