import { navigate } from "@reach/router";
import logo from "assets/images/pocketbook-logo.png";
import signup_logo from "assets/images/sign-up-bg.png";
import {
  PocketbookLogo,
  ChampionBgSection,
  BottomButton,
  LeftSection,
  SignupRightImage,
  SignupWrapper,
  SignupLeftHeader,
  SignupLeftFooter,
  ConfirmTitle,
} from "./styled";
import { rootRoute } from "routes";
import styled from "styled-components";
import { textStyles } from "ui";
import { useEffect, useRef, useState } from "react";
import { BsArrowLeft } from "react-icons/bs";

const useCountDown = ({ miliseconds, onComplete }) => {
  const id = useRef(0);
  const [time, setTime] = useState(miliseconds);

  useEffect(() => {
    id.current = setInterval(handleRemainingTime, 1000);
    return () => clearInterval(id.current);
  }, [])

  function handleRemainingTime() {
    setTime((time) => {
      if (time - 1000 <= 0) {
        clearInterval(id.current);
        onComplete?.();

        return 0;
      }

      return time - 1000;
    });
  }

  return time;
};

const EmailConfirm = () => {
  const time = useCountDown({
    miliseconds: 5000,
    onComplete: handleRedirect,
  });

  function handleRedirect() {
    const params = new URLSearchParams(document.location.search);
    const subdomain = params.get("subdomain");
    if (subdomain) {
      return window.location.replace(`https://${subdomain}`);
    }

    navigate("/login");
  }

  return (
    <SignupWrapper>
      <LeftSection>
        <SignupLeftHeader>
          <PocketbookLogo
            src={logo}
            alt="logo"
            onClick={() => navigate(rootRoute)}
          />
        </SignupLeftHeader>
        <EmailConfirmSection>
          <div>
            <ConfirmTitle>Great! Your email has been confirmed.</ConfirmTitle>
            <Description>
              Taking you to your account in <Timer>{time / 1000}</Timer>
            </Description>
          </div>
        </EmailConfirmSection>
        <SignupLeftFooter>
          <BottomButton
            onClick={handleRedirect}
            style={{
              marginBottom: "8px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <BsArrowLeft style={{ marginRight: "4px" }} /> Login
          </BottomButton>
          <BottomButton>
            Questions? Feel free to{" "}
            <a
              href="mailto:support@pocketbook.tech"
              style={{ color: "#939393" }}
            >
              contact our team.
            </a>
          </BottomButton>
        </SignupLeftFooter>
      </LeftSection>
      <ChampionBgSection>
        <SignupRightImage src={signup_logo} alt="signup-right-image" />
      </ChampionBgSection>
    </SignupWrapper>
  );
};

const Timer = styled.span`
  font-size: 20px;
  font-weight: bold;
`;

const Description = styled.div`
  ${textStyles.body.b2}
  margin-top: 10px;
`;

const EmailConfirmSection = styled.div`
  width: 480px;
`;

export default EmailConfirm;
