import {actionTypes} from "./actionTypes" 

const initialState = {
  salesValue: null,
  savingAmount: null,
  profileInfo: {
    business_name: null,
    business_phone: null,
    business_email: null,
    business_subdomain: null,
    password: null,
    accepted_pb_tos_pp: null,
    business_address: null,
    business_dba: null,
    business_type: null,
    website: null,
    employees_number: null,
    industry_type: null,
    other_industry_type: null,
    time_zone: null,
    first_name: null,
    last_name: null,
    subscription_plan: null,
    user_id: ""
  },
  // TODO: This needs to be modified once we know what will be stored.
  plaidData: null
};

const profileReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case actionTypes.SALES_VALUE:
      return {
        ...state,
        salesValue: payload,
      };
    case actionTypes.SAVING_AMOUNT:
      return {
        ...state,
        savingAmount: payload,
      };
    case actionTypes.PLAID_DATA:
    return {
      ...state,
      plaidData: payload,
    };
    case actionTypes.PROFILE_INFO:
      return {
        ...state,
        profileInfo: {
          ...state.profileInfo,
          ...payload
        }
      };
    default:
      return state;
  }
};

export default profileReducer;
