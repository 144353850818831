import { useState, useEffect } from "react";
import axios from "axios";

export const useLinkToken = (cognitoId) => {
  const [linkToken, setLinkToken] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchLinkToken = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_PBTECH_API_ENDPOINT}/v0/platform/payment_methods/plaid/link-token`,
        {
          user_id: cognitoId,
        }
      );
      const linkToken = response.data.link_token;
      setLinkToken(linkToken);
      setLoading(false);
    } catch (err) {
      setError(err);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchLinkToken();
  }, []);

  return { linkToken, loading, error };
};
