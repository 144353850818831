import { useEffect, useState } from "react";
import { useQueryParams, useLocalStorage } from "./hooks";
import { validateIntegration } from "./funcs";

const ValidateIntegration = (props) => {
  const { integrationId } = props;

  const [userInfo, setUserInfo] = useState();
  //read localstorage for integrationId

  const [value, clearValue] = useLocalStorage(integrationId);
  const queryParams = useQueryParams({ integrationId, read: true });
  const [validationRes, setValidationRes] = useState();

  const handleValidation = async () => {
    const res = await validateIntegration(integrationId, userInfo, queryParams);
    setValidationRes(res);
  };

  useEffect(() => {
    setUserInfo(value);
    clearValue(integrationId);
  }, [value, clearValue, integrationId]);

  useEffect(() => {
    if (validationRes?.status == "active") {
      window.location.href = `${userInfo.REDIRECT_URI}/integrations?integrationId=${integrationId}&status=${validationRes.status}`;
    }
  }, [validationRes]);

  // return (
  //     <div onClick={() => console.log(validationRes)}>Redirecting to pocketbook platform</div>
  // )
  useEffect(() => {
    if (userInfo) {
      handleValidation();
    }
  }, [userInfo]);

  return (
    <div onClick={() => console.log(validationRes)}>
      Redirecting to pocketbook platform
    </div>
  );
};

export default ValidateIntegration;
