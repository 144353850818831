import { CustomizeUrl, OnboardingLayout } from "./components";
import rightImage from "assets/images/cellphone-closeup.png";

export const PersonalUrl = () => {
  return (
    <OnboardingLayout rightImage={rightImage} isUrlPage>
      <CustomizeUrl />
    </OnboardingLayout>
  );
};
