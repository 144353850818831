import { Plans, OnboardingLayout } from "./components";
import { ContactFooter } from "./components";

export const SubscriptionPlan = () => {
  
  return (
    <OnboardingLayout footer={<ContactFooter/>}>
      <Plans/>
    </OnboardingLayout>
  );
};
