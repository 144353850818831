import React from "react";
import { SignupFormTitle } from "../styled";
import styled from "styled-components";
import plaidLogo from "assets/images/plaidVector.svg";
import { navigate } from "@reach/router";
import { onboardingRoutes } from "routes";
import { useLinkToken } from "../hooks";
import { usePlaidLink } from "react-plaid-link";
import { CircularProgress } from "@mui/material";
import { setAccessToken, getAuthInfo } from "../utils";
import { useDispatch, useSelector } from "react-redux";
import { actionTypes } from "redux/actionTypes";

import { Button } from "ui";

export const PlaidConnection = () => {
  const dispatch = useDispatch();
  const user_id = useSelector((state) => state?.profile?.profileInfo?.user_id);
  const { linkToken, loading, error } = useLinkToken(user_id);

  const { open, ready } = usePlaidLink({
    token: linkToken,
    onSuccess: async (public_token, metadata) => {
      const plaidData = await setAccessToken(public_token, metadata, user_id);
      dispatch({
        type: actionTypes.PLAID_DATA,
        payload: plaidData,
      });
      navigate(onboardingRoutes.confirmPlan);
    },
  });

  return (
    <>
      <SignupFormTitle>
        Connect your
        <br />
        bank to pay
      </SignupFormTitle>
      <DescriptionWrapper>
        Plaid makes it easy, safe and reliable for you to connect your bank
        account to Pocketbook and is the safer way to link financial accounts;
        trusted by today’s leading brands.
      </DescriptionWrapper>
      <Button
        marginTop={24}
        variant="black"
        onClick={() => open()}
        disabled={!ready}
      >
        {ready ? (
          <ConnectText>
            <img src={plaidLogo} alt="plaidLogo" />
            Connect
          </ConnectText>
        ) : (
          <CircularProgress style={{ color: "#FFF" }} size={12} />
        )}
      </Button>
    </>
  );
};

const DescriptionWrapper = styled.div`
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 133%;
  margin-top: 15px;
`;

const ConnectText = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;
