import { TextField, IconButton, InputAdornment, MenuItem } from "@mui/material";
import {
  BsFillEyeFill,
  BsFillEyeSlashFill,
  BsFillCheckCircleFill
} from "react-icons/bs";
import {FiAlertCircle} from "react-icons/fi"
import InputMask from "react-input-mask";
import { ErrMsg } from "../styled.js";

const handleTabKeyPress = (event) => {
  if (event.key === "Tab") {
    event.preventDefault();
    const currentTextField = event.target;
    const textFields = Array.from(document.querySelectorAll("input"));
    const currentIndex = textFields.indexOf(currentTextField);
    const nextTextField = textFields[currentIndex + 1];
    if (nextTextField) {
      nextTextField.focus();
    }
  }
};

// const handlePasswordTabKeyPress = (event) => {
//   if (event.key === "Tab") {
//     event.preventDefault();
//     const currentTextField = event.target;
//     const textFields = Array.from(
//       document.querySelectorAll('input[type="password"]')
//     );
//     const currentIndex = textFields.indexOf(currentTextField);
//     const nextTextField = textFields[currentIndex + 1];
//     if (nextTextField) {
//       nextTextField.focus();
//     }
//   }
// };

export const StyledTextField = ({
  name,
  value,
  placeholder,
  validation,
  errMsg,
  isSubmitted,
  handleChange,
  showDescription,
  type="text",
  autoComplete,
  width
}) => {
  return (
    <TextField
      type={type}
      name={name}
      value={value}
      placeholder={placeholder}
      autoComplete={autoComplete}
      variant="standard"
      sx={{
        width: width? width:"100%",
        marginBottom: showDescription ? "10px" : "40px",
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            {name !== "dbaName" && (
              <>
                {name === "subdomain" && (
                  <div className="flex align-items-center" style={{
                    color: "#000"
                  }}>
                    .pocketbook.tech
                  </div>
                )}
                {validation === true && (
                  <BsFillCheckCircleFill color="green"/>
                )}
                {validation === false && (
                  <div className="flex align-items-center">
                    {isSubmitted && <ErrMsg>{errMsg}</ErrMsg>}
                    <FiAlertCircle color="red"/>
                  </div>
                )}
              </>
            )}
          </InputAdornment>
        ),
        sx: {
          fontSize: "14px",
          padding: "0 0 5px",
        },
      }}
      onKeyDown={handleTabKeyPress}
      onChange={handleChange}
    />
  );
};

export const StyledPhoneField = ({
  name,
  value,
  placeholder,
  validation,
  errMsg,
  isSubmitted,
  handleChange,
}) => {
  return (
    <InputMask
      mask="999 999 9999"
      value={value}
      disabled={false}
      maskChar=""
      onChange={handleChange}
    >
      {() => (
        <TextField
          type="text"
          name={name}
          placeholder={placeholder}
          variant="standard"
          sx={{
            width: "100%",
            marginBottom: "40px",
          }}
          id="formatted-text-mask-input"
          value={value}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {validation === true && (
                  <BsFillCheckCircleFill color="green"/>
                )}
                {validation === false && (
                  <div className="flex align-items-center">
                    {isSubmitted && <ErrMsg>{errMsg}</ErrMsg>}
                    <FiAlertCircle color="red"/>
                  </div>
                )}
              </InputAdornment>
            ),
            sx: {
              fontSize: "14px",
              padding: "0 0 5px",
            },
          }}
          onKeyDown={handleTabKeyPress}
        />
      )}
    </InputMask>
  );
};

export const StyledSelector = ({
  label,
  name,
  value,
  handleChange,
  selectList,
}) => {
  return (
    <TextField
      label={label}
      name={name}
      variant="standard"
      value={value}
      sx={{
        width: "100%",
        "& .MuiSvgIcon-root": {
          top: "calc(50% - 0.9em)",
        },
        "& .MuiFormLabel-root": {
          fontSize: "14px",
        },
        marginBottom: "40px",
      }}
      InputProps={{
        sx: {
          fontSize: "14px",
          padding: "0 0 20px",
        },
      }}
      onChange={handleChange}
      select
    >
      {selectList.map((item, index) => (
        <MenuItem value={item.value} key={index}>
          {item.label}
        </MenuItem>
      ))}
    </TextField>
  );
};

export const StyledPasswordField = ({
  name,
  value,
  placeholder,
  validation,
  errMsg,
  isSubmitted,
  handleChange,
  showPassword,
  handleShowPassword,
}) => {
  return (
    <TextField
      type={showPassword ? "text" : "password"}
      name={name}
      value={value}
      placeholder={placeholder}
      variant="standard"
      sx={{
        width: "100%",
        marginBottom: "40px",
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={handleShowPassword}>
              {showPassword ? <BsFillEyeFill /> : <BsFillEyeSlashFill />}
            </IconButton>

            {validation === true && (
              <BsFillCheckCircleFill color="green"/>
            )}
            {validation === false && (
              <div className="flex align-items-center">
                {isSubmitted && <ErrMsg>{errMsg}</ErrMsg>}
                <FiAlertCircle color="red"/>
              </div>
            )}
          </InputAdornment>
        ),
        sx: {
          fontSize: "14px",
          padding: "0 0 20px",
        },
      }}
      onKeyDown={handleTabKeyPress}
      onChange={handleChange}
    />
  );
};
