import axios from "axios";

// PLAID
export const getAuthInfo = async (cognitoId) => {
  try {
    const apiUrl = `${process.env.REACT_APP_PBTECH_API_ENDPOINT}/v0/platform/payment_methods/plaid/auth`;

    const headers = {
      "Content-Type": "application/json",
    };

    const responseData = await axios.post(
      apiUrl,
      { cognito_id: cognitoId },
      { headers }
    );
    return responseData;
  } catch (err) {
    console.error("Error fetching access token:", err);
    if (err.response) {
      console.error("Server responded with:", err.response.data);
    }
  }
};

export const setAccessToken = async (publicToken, metadata, cognitoId) => {
  try {
    const apiUrl = `${process.env.REACT_APP_PBTECH_API_ENDPOINT}/v0/platform/payment_methods/plaid/set_access_token`;

    const headers = {
      "Content-Type": "application/json",
    };

    const responseData = await axios.post(
      apiUrl,
      {
        public_token: publicToken,
        metadata: metadata,
        cognito_id: cognitoId,
        payment_method_default: true,
      },
      { headers }
    );
    return responseData;
  } catch (err) {
    console.error("Error fetching access token:", err);
    if (err.response) {
      console.error("Server responded with:", err.response.data);
    }
  }
};
