import React, { useState } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { TextField } from "@mui/material";
import { navigate } from "@reach/router";
// import SwitchSelector from "react-switch-selector";
import { NumericFormat } from "react-number-format";
import * as actionTypes from "./actionTypes";
import logo from "assets/images/pocketbook-logo-white.png";
import plan_bg from "assets/images/plan-bg.png";
import plan_macbook from "assets/images/plan-macbook.png";
import plan_bg_2 from "assets/images/plan-bg-2.png";
import {
  Container,
  MainSection,
  PlanLogo,
  SectionTitle,
  PlanGrid,
  PlanCard,
  PlanSection,
  PlanTitle,
  PlanFeature,
  PlanBtn,
  CalculateSection,
  StyledTitle,
  StyledSubTitle,
  StyledBlackBtn,
  FeatureSection,
  FeatureBadge,
  CalculateForm,
  SavingResult,
  BgImage,
  FeatureHeader,
  MacbookImg,
  PaymentTabSection,
  SelectedTab,
  TabTitle,
  SelectedTabDescription,
  StyledTab,
  TabDescription,
  TrialSection,
  StyledWhiteBtn,
  TrialBtnSection,
  PlanFooter,
  FooterLogo,
  FooterTitle,
  FooterSubTitle,
  SupportDomain,
  FeatureDetail,
  PlanSubTitle,
  PlanHeader,
  NavSection,
  NavItem,
  HeaderSignupNav,
  FooterBottomText,
} from "./styled";

// const calculateOptions = [
//   {
//     label: <span className="font-14">Monthly</span>,
//     value: {
//       monthly: true,
//     },
//     selectedBackgroundColor: "#ffffff",
//   },
//   {
//     label: <span className="font-14">Annually</span>,
//     value: "annually",
//     selectedBackgroundColor: "#ffffff",
//   },
// ];

const paymentOptions = [
  {
    value: "hassle",
    title: "Hassle-Free Invoicing",
    description:
      "Send custom invoices with alerts and ensure you get paid quickly and efficiently.",
  },
  {
    value: "expedited",
    title: "Expedited Payments",
    description:
      "Simplify the pay-by-bank flow process with expedited ACH payments and improve your cash flow.",
  },
  {
    value: "flexible",
    title: "Flexible Payment Methods",
    description:
      "Accept and pay with popular methods such as ACH, PayPal, and Venmo.",
  },
];

const NumericFormatCustom = React.forwardRef(function NumericFormatCustom(
  props,
  ref
) {
  const { onChange, ...other } = props;
  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      valueIsNumericString
      prefix="$"
    />
  );
});

NumericFormatCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const Plan = () => {
  const dispatch = useDispatch();
  const [salesValue, setSalesValue] = useState("");
  const [savingAmount, setSavingAmount] = useState("");
  const [savingValidation, setSavingValidation] = useState(true);
  const [submitStatus, setSubmitStatus] = useState(false);
  const [selectedPayment, setSelectedPayment] = useState("hassle");

  const handleChange = (event) => {
    setSubmitStatus(false);
    if (!event.target.value) {
      setSalesValue(0);
      setSavingAmount(0);
    } else {
      const valid = /^\d+$/.test(event.target.value);
      if (valid) {
        setSavingValidation(true);
        setSalesValue(event.target.value);
      } else {
        setSavingValidation(false);
      }
    }
  };

  const submitPlan = () => {
    dispatch({
      type: actionTypes.SALES_VALUE,
      payload: salesValue,
    });
    dispatch({
      type: actionTypes.SAVING_AMOUNT,
      payload: savingAmount,
    });
    navigate("/signup");
  };

  // const initialSelectedIndex = calculateOptions.findIndex(
  //   ({ value }) => value === "monthly"
  // );

  const convertCommasNumber = (value) => {
    const numberWithCommas = value
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return numberWithCommas;
  };

  // const onChangeCalculateSwitch = (newValue) => {
  //   console.log("calculate switch", newValue);
  // };

  const onSelectPayment = (option) => {
    setSelectedPayment(option.value);
  };

  const onClickSavingBtn = () => {
    console.log("saving amount", savingAmount);
    const numberWithCommas = convertCommasNumber(
      (salesValue * (3 / 100 - 1 / 200) - 29 * 12).toFixed(2)
    );
    setSavingAmount(numberWithCommas);
    setSubmitStatus(true);
  };

  return (
    <Container>
      <BgImage src={plan_bg} alt="plan-background" />
      <MainSection>
        <PlanHeader>
          <PlanLogo src={logo} alt="logo" />
          <NavSection>
            <NavItem>Features</NavItem>
            <NavItem>Pricing</NavItem>
            <NavItem onClick={() => navigate("login")}>Login</NavItem>
            <HeaderSignupNav onClick={() => navigate("signup")}>
              Sign up
            </HeaderSignupNav>
          </NavSection>
        </PlanHeader>
        <div className="text-center">
          <SectionTitle>The best business tools. All in one.</SectionTitle>
          {/* <SectionDescription>
            Pocketbook keeps things simple. We offer one easy subscription that
            gives you access to all our features. No confusion, just everything
            you need.
          </SectionDescription> */}
        </div>
        <PlanGrid>
          <PlanCard>
            <PlanTitle>$19/month</PlanTitle>
            <PlanSubTitle>
              0.50% ACH Payments <br /> Lowest in Industry
            </PlanSubTitle>
            <PlanSection>
              <PlanFeature>White label customizable Invoicing</PlanFeature>
              <PlanFeature>Contracts</PlanFeature>
              <PlanFeature>Marketing and Info Campaigns</PlanFeature>
              <PlanFeature>ACH Payments as fast as same day</PlanFeature>
              <PlanFeature>Linked accounts</PlanFeature>
              <PlanFeature>White Label Dashboard and URL</PlanFeature>
              <PlanFeature>Text Invoice Delivery</PlanFeature>
              <PlanFeature>Paypal and Venmo Integrations</PlanFeature>
            </PlanSection>
            <PlanBtn onClick={submitPlan}>Sign up</PlanBtn>
          </PlanCard>
        </PlanGrid>
        <CalculateSection>
          <FeatureBadge>Savings Calculator</FeatureBadge>
          <StyledTitle>
            Find out how much you'll save <br /> in processing costs with
            Pocketbook
          </StyledTitle>
          <StyledSubTitle>
            Add your estimated annual credit card sales below to find out!
          </StyledSubTitle>
          {/* <SwitchSection>
            <SwitchSelector
              onChange={onChangeCalculateSwitch}
              options={calculateOptions}
              initialSelectedIndex={initialSelectedIndex}
              backgroundColor={"rgba(0, 0, 0, 0.17)"}
              fontColor={"#000000"}
            />
          </SwitchSection> */}
          <div>
            <CalculateForm>
              <TextField
                label=""
                value={salesValue}
                onChange={handleChange}
                name="salesValue"
                id="outlined-adornment-amount"
                InputProps={{
                  inputComponent: NumericFormatCustom,
                  style: {
                    height: 41,
                    width: 250,
                  },
                }}
              />
              <StyledBlackBtn onClick={onClickSavingBtn}>
                See savings
              </StyledBlackBtn>
            </CalculateForm>
            {savingAmount !== 0 && savingAmount !== "" && submitStatus && (
              <div>
                {salesValue > 35000 && savingValidation && (
                  <SavingResult>
                    Your business could be saving ${savingAmount} annually*
                  </SavingResult>
                )}
                {salesValue !== "" &&
                  salesValue < 35000 &&
                  savingValidation && (
                    <SavingResult>
                      Annual credit card sales must be above $35,000
                    </SavingResult>
                  )}
                {!savingValidation && (
                  <SavingResult>
                    Annual credit card sales must be number!
                  </SavingResult>
                )}
              </div>
            )}
          </div>
        </CalculateSection>
        <FeatureSection>
          <div className="text-center">
            <FeatureHeader>
              <FeatureBadge>Features</FeatureBadge>
              <StyledTitle>
                All the tools you need to run your business and save money
              </StyledTitle>
              <StyledSubTitle>
                With Pocketbook, you can easily create and send invoices, get
                paid quickly, and access a host of other features from anywhere,
                at any time.
              </StyledSubTitle>
            </FeatureHeader>
          </div>
          <FeatureDetail>
            <PaymentTabSection>
              {paymentOptions.map((option, index) =>
                selectedPayment === option.value ? (
                  <SelectedTab key={`tab_${index}`}>
                    <TabTitle>{option.title}</TabTitle>
                    <SelectedTabDescription>
                      {option.description}
                    </SelectedTabDescription>
                  </SelectedTab>
                ) : (
                  <StyledTab
                    key={`tab_${index}`}
                    onClick={() => {
                      onSelectPayment(option);
                    }}
                  >
                    <TabTitle>{option.title}</TabTitle>
                    <TabDescription>{option.description}</TabDescription>
                  </StyledTab>
                )
              )}
            </PaymentTabSection>
            <MacbookImg src={plan_macbook} alt="macbook" />
          </FeatureDetail>
        </FeatureSection>
      </MainSection>
      <img src={plan_bg_2} alt="plan-background-2" width={"100%"} />
      <TrialSection>
        <FeatureBadge>Contact us</FeatureBadge>
        <StyledTitle>
          Questions about how Pocketbook can help your business?
        </StyledTitle>
        <StyledSubTitle>
          Our friendly team is always here to chat.
        </StyledSubTitle>
        <SupportDomain>support@pocketbook.tech</SupportDomain>
      </TrialSection>
      <PlanFooter>
        <FooterTitle>Start your 14-day free trial</FooterTitle>
        <FooterSubTitle>
          It's time to save money and optimize your business.
        </FooterSubTitle>
        <TrialBtnSection>
          <StyledBlackBtn>Learn more</StyledBlackBtn>
          <StyledWhiteBtn
            onClick={() => {
              navigate("/signup");
            }}
          >
            Sign up
          </StyledWhiteBtn>
        </TrialBtnSection>
        <FooterLogo src={logo} alt="footer-logo" />
        <NavSection>
          <NavItem>Support</NavItem>
          <NavItem>Features</NavItem>
          <NavItem>Pricing</NavItem>
          <NavItem>Terms</NavItem>
          <NavItem>Privacy</NavItem>
        </NavSection>
        <FooterBottomText>
          © 2023 Pocketbook. All rights reserved.
        </FooterBottomText>
      </PlanFooter>
    </Container>
  );
};

export default Plan;
