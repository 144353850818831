import React, { useState } from "react";
import styled from "styled-components";
import { SignupFormTitle } from "../styled";
import { navigate } from "@reach/router";
import { onboardingRoutes } from "routes";
import { useDispatch, useSelector } from "react-redux";
import { actionTypes } from "../../../redux/actionTypes";
import { addPlan } from "../utils";
import { CircularProgress } from "@mui/material";

const PLANS = {
  monthly: "Monthly",
  yearly: "Yearly",
};

export const PlanConfirmation = () => {
  const currentPlan = useSelector(
    (state) => state.profile.profileInfo.subscription_plan
  );
  const userId = useSelector((state) => state.profile.profileInfo.user_id);
  const dispatch = useDispatch();
  const [chosenPlan, setChosenPlan] = useState(currentPlan);
  const [loading, setLoading] = useState(false);

  console.log(userId, chosenPlan);

  const handlePlan = async () => {
    if (chosenPlan) {
      setLoading(true);
      dispatch({
        type: actionTypes.PROFILE_INFO,
        payload: {
          subscription_plan: chosenPlan,
        },
      });
      await addPlan(userId, {
        subscription_plan: chosenPlan,
      });
      setLoading(false);
      navigate(onboardingRoutes.personalUrl);
    }
  };

  return (
    <>
      <SignupFormTitle>Confirm your plan</SignupFormTitle>
      <DescriptionWrapper>
        <MainDescription>
          Your bank has been securely connected with Plaid.
        </MainDescription>
      </DescriptionWrapper>
      <OptionsWrapper>
        <Option
          onClick={() => setChosenPlan(PLANS.monthly)}
          $isSelected={chosenPlan === PLANS.monthly}
        >
          <OptionTitle>Monthly</OptionTitle>
          <OptionPrice>$19</OptionPrice>
        </Option>
        <Option
          onClick={() => setChosenPlan(PLANS.yearly)}
          $isSelected={chosenPlan === PLANS.yearly}
        >
          <OptionTitle>Annual</OptionTitle>
          <OptionPrice>$9 per month</OptionPrice>
          <OptionDescription>
            Total $108, saving you $10 per month
          </OptionDescription>
        </Option>
      </OptionsWrapper>
      <ConnectButton onClick={handlePlan}>
        {loading ? (
          <CircularProgress style={{ color: "#FFF" }} size={12} />
        ) : (
          "Subscribe"
        )}
      </ConnectButton>
      <SubtextWrapper>
        <MainSubtext>
          By clicking Subscribe, you authorize Pocketbook to debit your linked
          bank account according to the terms of your subscription.
        </MainSubtext>
        <SubText>
          Upon completion of the 14-day trial period, the account you linked
          will automatically be charged. However, should you decide to terminate
          your subscription prior to the trial's conclusion, no charges will be
          incurred. This account will also be charged on the monthly or annual
          anniversary of the date your account was initially charged, as
          applicable, until cancelled. You may cancel your subscription at any
          time by contacting us at support@pocketbook.tech. Our customer support
          team is available to address any inquiries or concerns regarding
          billing or subscriptions. Thank you for entrusting Pocketbook with
          your business management and cost savings needs. Please note that
          subscriptions are non-refundable.
        </SubText>
      </SubtextWrapper>
    </>
  );
};

const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 22px;
`;

const MainDescription = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
`;

const OptionsWrapper = styled.div`
  margin-top: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
  max-width: 343px;
`;

const Option = styled.div`
  padding: 16px;
  border-radius: 12px;
  border: 1px solid #000;
  background-color: ${(props) =>
    props.$isSelected ? "rgba(147, 147, 147, 0.25)" : "FFFFF"};

  &:hover {
    background-color: rgba(147, 147, 147, 0.5);
    cursor: pointer;
  }
`;

const OptionTitle = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
`;

const OptionPrice = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
`;

const OptionDescription = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #939393;
  margin-top: 8px;
`;

const ConnectButton = styled.button`
  background-color: #000;
  margin-top: 26px;
  padding: 12px 16px;
  border-radius: 12px;
  border: none;
  color: #fff;
  font-size: 14px;

  &:hover {
    opacity: 0.85;
    cursor: pointer;
  }
`;

const SubText = styled.div`
  color: #939393;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
`;

const MainSubtext = styled.div`
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  margin-top: 28px;
`;

const SubtextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;
