import { connectQuickBook, connectXero } from "./funcs";
import { useQueryParams } from "./hooks";
import { IntegrationsWrapper, IntegrationsHeader } from "./styled";

const Integrations = (props) => {
  const { integrationId } = props;
  const queryParams = useQueryParams({ integrationId });

  // TODO: CHANGE FOR REDUX ACTIONS REDUCER ETC... I'M A NOOB
  if (integrationId === "quickbooks") {
    connectQuickBook(queryParams.apiKey, queryParams.cognitoId);
  }
  if (integrationId === "xero") {
    connectXero(queryParams.apiKey, queryParams.cognitoId);
  }

  return (
    <IntegrationsWrapper>
      <IntegrationsHeader integration={integrationId} />
    </IntegrationsWrapper>
  );
};

export default Integrations;
