// export const login = async (payload) => {
//   const options = {
//     method: "POST",
//     headers: {
//       "Content-Type": "application/json",
//     },
//     body: JSON.stringify(payload),
//   };
//   const response = await fetch(
//     `https://api.pocketbook.tech/dev/v0/auth/sign_in/${process.env.REACT_APP_CLIENT_COGNITO_ID}`,
//     options
//   );
//   const res = await response.json();
//   return res;
// };

export const getPlatformSubdomain = async (email) => {
  const options = {
    method: "GET",
  };

  try {
    const response = await fetch(
      `https://api.pocketbook.tech/dev/v0/auth/platform_subdomain/${email}`,
      options
    );

    const data = await response.json();
    if (!response.ok) {
      throw new Error(data.detail || "Server error.");
    }

    return data;
  } catch (e) {
    return {
      exists: false,
      message: e.message,
    };
  }
};
