import React from 'react'
import { OnboardingLayout, PlanConfirmation } from './components'
import { ContactFooter } from './components'

export const ChosenPlanConfirm=()=>{
  return (
    <OnboardingLayout footer={<ContactFooter/>}>
        <PlanConfirmation/>
    </OnboardingLayout>
  )
}
