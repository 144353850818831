import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SignupFormTitle } from "../styled";
import styled, { css } from "styled-components";
import { navigate } from "@reach/router";
import { onboardingRoutes } from "routes";
import { actionTypes } from "../../../redux/actionTypes";
import { addPlan } from "../utils";
import { CircularProgress } from "@mui/material";
import { Button } from "ui";

const PLANS = {
  monthly: "Monthly",
  yearly: "Yearly",
};

export const Plans = () => {
  const dispatch = useDispatch();
  const userId = useSelector((state) => state.profile.profileInfo.user_id);
  const [chosenPlan, setChosenPlan] = useState(PLANS.monthly);
  const [loading, setLoading] = useState(false);

  const handlePlan = async () => {
    if (chosenPlan) {
      setLoading(true);
      dispatch({
        type: actionTypes.PROFILE_INFO,
        payload: {
          subscription_plan: chosenPlan,
        },
      });
      await addPlan(userId, {
        subscription_plan: chosenPlan,
      });
      setLoading(false);
      navigate(onboardingRoutes.personalUrl);
    }
  };

  return (
    <>
      <SignupFormTitle>Choose your plan</SignupFormTitle>
      <DescriptionWrapper>
        <MainDescription>
          Pocketbook keeps things simple. We offer one easy autopay subscription
          that gives you access to all our features. All you need to do is
          select monthly or annual billing.
        </MainDescription>
        <FreeTrial>
          Plus, your first 14 days are free! No payment required until the trial
          ends.
        </FreeTrial>
      </DescriptionWrapper>
      <OptionsWrapper>
        <Option
          onClick={() => setChosenPlan(PLANS.monthly)}
          $isSelected={chosenPlan === PLANS.monthly}
        >
          <OptionTitle>Monthly</OptionTitle>
          <OptionPrice>$19</OptionPrice>
        </Option>
        <Option
          onClick={() => setChosenPlan(PLANS.yearly)}
          $isSelected={chosenPlan === PLANS.yearly}
        >
          <OptionTitle>Annual</OptionTitle>
          <OptionPrice>$9 per month</OptionPrice>
          <OptionDescription>
            Total $108, saving you $10 per month
          </OptionDescription>
        </Option>
      </OptionsWrapper>
      <ConnectButton onClick={handlePlan}>
        {loading ? (
          <CircularProgress style={{ color: "#FFF" }} size={12} />
        ) : (
          "Subscribe"
        )}
      </ConnectButton>
      <SubtextWrapper>
        <MainSubtext>
          By clicking Subscribe, you authorize Pocketbook to debit your linked
          bank account according to the terms of your subscription.
        </MainSubtext>
        <SubText>
          You will have a 14-day trial period before your account is charged.
          You must link your bank account by the end of this trial period. If
          you cancel your subscription before the trial ends, no charges will be
          incurred. After the trial, your linked account will be automatically
          charged, and it will continue to be charged on a monthly or annual
          basis until you cancel. You can cancel your subscription at any time
          by contacting us at support@pocketbook.tech. Thank you for entrusting
          Pocketbook with your business management and cost savings needs.
          Please note that subscriptions are non-refundable.
        </SubText>
      </SubtextWrapper>
    </>
  );
};

const Description = css`
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
`;

const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 22px;
`;

const MainDescription = styled.div`
  ${Description};
`;

const FreeTrial = styled.div`
  ${Description};
  font-weight: 700;
  font-size: 18px;
`;

const OptionsWrapper = styled.div`
  margin-top: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
  max-width: 343px;
`;

const Option = styled.div`
  padding: 16px;
  border-radius: 12px;
  border: 1px solid #000;
  background-color: ${(props) =>
    props.$isSelected ? "rgba(147, 147, 147, 0.25)" : "FFFFF"};

  &:hover {
    background-color: rgba(147, 147, 147, 0.5);
    cursor: pointer;
  }
`;

const OptionTitle = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
`;

const OptionPrice = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
`;

const OptionDescription = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #939393;
  margin-top: 8px;
`;

const ConnectButton = styled.button`
  background-color: #000;
  margin-top: 26px;
  padding: 12px 16px;
  border-radius: 12px;
  border: none;
  color: #fff;
  font-size: 14px;

  &:hover {
    opacity: 0.85;
    cursor: pointer;
  }
`;

const SubText = styled.div`
  color: #939393;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
`;

const MainSubtext = styled.div`
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  margin-top: 28px;
`;

const SubtextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;
