import { useLocation } from "@reach/router";
import { useEffect, useState } from "react";

const useQueryParams = ({ integrationId, read = false }) => {
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const queryParams = {};
  for (let param of searchParams.entries()) {
    const [key, value] = param;
    queryParams[key] = value;
  }
  useEffect(() => {
    if (!read) {
      localStorage.setItem(integrationId, JSON.stringify(queryParams));
    }
  }, [integrationId, queryParams, read]);

  return queryParams;
};

const useLocalStorage = (key) => {
  const [value] = useState(() => {
    const storedValue = localStorage.getItem(key);
    return JSON.parse(storedValue);
  });
  const clearValue = () => {
    localStorage.removeItem(key);
  };
  return [value, clearValue];
};

export { useQueryParams, useLocalStorage };
