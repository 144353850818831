import React from 'react'
import { 
    SignupLeftFooter, 
    BottomButton
} from '../styled';

export const ContactFooter=()=>{
  return (
    <SignupLeftFooter>
    <BottomButton>
    Questions? Feel free to{" "}
    <a
        href="mailto:support@pocketbook.tech"
        style={{ color: "#939393" }}
    >
        contact our team
    </a>
    .
    </BottomButton>
    </SignupLeftFooter> 
  )
}

export const PlaidFooter=()=>{
  return (
    <SignupLeftFooter>
    <BottomButton>
    Learn more about{" "}
    <a
        href="https://plaid.com/"
        style={{ color: "#939393" }}
    >
        Plaid.
    </a>
    </BottomButton>
    </SignupLeftFooter> 
  )
}

