export const checkSubDomain = async (value) => {
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      subdomain: value,
    }),
  };
  const response = await fetch(
    `https://api.pocketbook.tech/dev/v0/tenancy/clients/check_subdomain`,
    options
  );
  const res = await response.json();
  return res;
};

export const createClient = async (payload) => {
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  };
  const response = await fetch(
    `https://api.pocketbook.tech/dev/v0/tenancy/clients/`,
    options
  );
  const res = await response.json();
  return res;
};

export const createUserId = async (payload) => {
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  };
  let res;
  try {
    const response = await fetch(
      `https://api.pocketbook.tech/dev/v0/tenancy/clients/create_user_id`,
      options
    );

    res = await response.json();

    if (!response.ok) {
      throw new Error();
    }

    return res;
  } catch (error) {
    throw new Error(res.detail);
  }
};

export const addPlan = async (userId, payload) => {
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  };
  const response = await fetch(
    `https://api.pocketbook.tech/dev/v0/platform/subscription/${userId}`,
    options
  );
  const res = await response.json();
  return res;
};
