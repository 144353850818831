export const rootRoute="/"

export const onboardingRoutes={
    signUp: "signup",
    subscription: "subscription",
    connectBank: "connect_bank",
    confirmPlan: "confirm_plan",
    personalUrl: "personal_url",
    success: "success"
}
