import styled from "styled-components";

export const Container = styled.div`
  background-color: #ffffff;
  position: relative;
`;

export const MainSection = styled.div`
  padding: 50px;
  position: relative;

  @media (max-width: 600px) {
    padding: 50px;
  }
  @media (max-width: 400px) {
    padding: 40px;
  }
`;

export const PlanHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;

  @media (max-width: 750px) {
    display: grid;
    justify-content: center;
  }
`;

export const PlanLogo = styled.img`
  width: 240px;
  height: 40px;
  @media (max-width: 750px) {
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
  }
  @media (max-width: 500px) {
    width: 200px;
    height: auto;
  }
`;

export const NavSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 40px;

  @media (max-width: 500px) {
    gap: 20px;
  }
`;

export const NavItem = styled.div`
  color: #ffffff;
  font-family: Inter;
  font-weight: 590;
  font-size: 14px;
  line-height: 17px;
  cursor: pointer;
`;

export const HeaderSignupNav = styled.div`
  background-color: #ffffff;
  font-family: Inter;
  font-weight: 590;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  padding: 10px 18px;
  cursor: pointer;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
`;

export const BgImage = styled.img`
  object-fit: cover;
  object-position: top;
  width: 100%;
  height: 700px;
  position: absolute;
  top: 0px;
`;

export const SectionTitle = styled.div`
  font-size: 48px;
  font-weight: 590;
  font-family: Inter;
  line-height: 2.4rem;
  color: #ffffff;
  padding-bottom: 24px;

  @media (max-width: 600px) {
    font-size: 32px;
    font-weight: 500;
  }
`;

export const SectionDescription = styled.div`
  font-size: 20px;
  font-weight: 400;
  font-family: Inter;
  line-height: 1.5rem;
  color: #ffffff;
  max-width: 800px;
  width: 100%;
  margin: auto;

  @media (max-width: 600px) {
    font-size: 18px;
    font-weight: 500;
  }
`;

export const PlanGrid = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  padding: 0px;
  gap: 2rem;
  margin-top: 1.5rem;

  @media (max-width: 600px) {
    margin-top: 1rem;
  }

  @media (max-width: 400px) {
    margin-top: 1rem;
  }
`;

export const PlanCard = styled.div`
  width: 380px;
  height: 100%;
  background: #ffffff;
  border: 1px solid #eaecf0;
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08),
    0px 4px 6px -2px rgba(16, 24, 40, 0.03);
  border-radius: 16px;
  padding: 38px 32px;
`;

export const PlanSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 25px;
  gap: 16px;
  text-align: center;
`;

export const PlatformName = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.875rem;
  text-align: center;
  color: #000000;
`;

export const PlanTitle = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 3.75rem;
  text-align: center;
  letter-spacing: -0.02em;
  color: #101828;

  @media (max-width: 600px) {
    font-size: 32px;
  }
`;

export const PlanSubTitle = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 1.25rem;
  line-height: 1.5rem;
  text-align: center;
  color: #000000;
  padding-top: 10px;
`;

export const PlanFeature = styled.div`
  display: list-item;
  font-family: "Inter";
  font-style: normal;
  font-weight: 590;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
`;

export const SavingResult = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.5rem;
  color: #000000;
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
`;

export const PlanBtn = styled.div`
  padding: 0.75rem 1.25rem;
  background: #000000;
  border: 1px solid #ffffff;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 1.5rem;
  color: #ffffff;
  margin-top: 40px;
  text-align: center;
  cursor: pointer;
`;

export const CalculateSection = styled.div`
  margin: 1.25rem auto;
  padding: 1.25rem 4.375rem;
  max-width: 56.25rem;
  width: 100%;
  text-align: center;
  background-color: #f9fafb;
  border-radius: 16px;

  @media (max-width: 600px) {
    margin: 1.25rem auto;
    padding: 2rem 2.125rem;
  }
`;

export const StyledTitle = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 590;
  font-size: 1.75rem;
  line-height: 2.625rem;
  text-align: center;
  color: #000000;
  padding-bottom: 1.25rem;

  @media (max-width: 500px) {
    font-size: 1.375rem;
  }
`;

export const StyledSubTitle = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 1.25rem;
  line-height: 1.5rem;
  text-align: center;
  color: #000000;

  @media (max-width: 500px) {
    font-size: 1rem;
  }
`;

export const CalculateForm = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 21px;

  @media (max-width: 420px) {
    display: grid;
  }
`;

export const StyledBlackBtn = styled.div`
  background-color: #000000;
  border: 1px solid #ffffff;
  filter: drop-shadow(0px 1px 2px rgba(16, 24, 40, 0.05));
  border-radius: 8px;
  padding: 0.75rem 1.25rem;
  color: #ffffff;
  font-family: "Inter";
  font-style: normal;
  font-weight: 590;
  font-size: 0.875rem;
  line-height: 1rem;
  width: fit-content;
  cursor: pointer;
  height: 2.563rem;
  margin-top: 0px;
  margin-bottom: auto;

  @media (max-width: 420px) {
    margin-top: 1rem;
    margin-left: 0px;
    max-width: 25rem;
  }
`;

export const StyledWhiteBtn = styled.div`
  background: #ffffff;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  padding: 0.75rem 1.25rem;
  color: #000000;
  font-family: "Inter";
  font-style: normal;
  font-weight: 590;
  font-size: 0.875rem;
  line-height: 1rem;
  width: fit-content;
  cursor: pointer;
  height: 2.563rem;
  margin-left: 1rem;
  margin-top: 0px;
  margin-bottom: auto;

  @media (max-width: 420px) {
    margin-top: 1rem;
    max-width: 25rem;
  }
`;

export const FeatureSection = styled.div`
  margin-top: 3.125rem;
`;

export const FeatureBadge = styled.div`
  width: fit-content;
  text-align: center;
  background-color: #ffffff;
  padding: 0.25rem 0.75rem;
  border-radius: 16px;
  color: #000000;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.25rem;
  margin: auto auto 1rem auto;
  border: 1px solid #000000;
`;

export const FeatureHeader = styled.div`
  width: 100%;
  max-width: 768px;
  margin: auto;
`;

export const FeatureDetail = styled.div`
  display: flex;
  align-items: center;
  height: 700px;

  @media (max-width: 1250px) {
    height: 500px;
  }

  @media (max-width: 1070px) {
    justify-content: center;
  }
`;

export const SwitchSection = styled.div`
  width: 157px;
  height: 30px;
  margin: 21px auto;
`;

export const MacbookImg = styled.img`
  width: auto;
  height: 800px;
  position: absolute;
  right: 0px;

  @media (max-width: 1400px) {
    height: 700px;
  }

  @media (max-width: 1250px) {
    height: 500px;
  }

  @media (max-width: 1070px) {
    display: none;
  }
`;

export const PaymentTabSection = styled.div`
  margin-left: 32px;
  width: 100%;
  max-width: 576px;
`;

export const SelectedTab = styled.div`
  padding: 16px 0px 16px 24px;
  border-left: 4px solid #000000;
  cursor: pointer;
`;

export const StyledTab = styled.div`
  padding: 16px 0px 16px 24px;
  border-left: 4px solid #f2f4f7;
  cursor: pointer;
`;

export const TabTitle = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 590;
  font-size: 22px;
  line-height: 26px;
  color: #000000;
  padding-bottom: 8px;

  @media (max-width: 500px) {
    font-size: 18px;
  }
`;

export const SelectedTabDescription = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #000000;

  @media (max-width: 500px) {
    font-size: 16px;
  }
`;

export const TabDescription = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #939393;

  @media (max-width: 500px) {
    font-size: 16px;
  }
`;

export const TrialSection = styled.div`
  background-color: #f5f5f7;
  padding: 49px 0px;
  text-align: center;
`;

export const TrialBtnSection = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 32px;
  padding-bottom: 40px;
  border-bottom: 1px solid #ffffff;
`;

export const PlanFooter = styled.div`
  padding: 60px 112px;
  background-color: #000000;
  position: relative;

  @media (max-width: 600px) {
    padding: 60px 42px;
  }
`;

export const FooterLogo = styled.img`
  width: 100%;
  max-width: 200px;
  height: auto;
  margin: 30px auto 20px auto;
  display: flex;

  @media (max-width: 600px) {
    max-width: 150px;
  }
`;

export const FooterBadge = styled.div`
  width: fit-content;
  text-align: center;
  background-color: #000000;
  border: 1px solid #ffffff;
  filter: drop-shadow(0px 1px 2px rgba(16, 24, 40, 0.05));
  border-radius: 16px;
  padding: 4px 12px;
  color: #ffffff;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  margin: auto auto 16px auto;
`;

export const FooterTitle = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 590;
  font-size: 28px;
  line-height: 36px;
  text-align: center;
  color: #ffffff;
  padding-bottom: 16px;
  width: 100%;
  max-width: 600px;
  margin: auto;

  @media (max-width: 600px) {
    font-size: 20px;
  }
`;

export const FooterSubTitle = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 590;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #ffffff;
`;

export const SupportDomain = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #000000;
  padding-top: 20px;

  @media (max-width: 600px) {
    font-size: 12px;
  }
`;

export const FooterBottomText = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #ffffff;
  padding-top: 30px;
`;
