// Password Validation
export const isValidPasswordLength = (password) => {
  // Minimum length is 12 characters
  if (password.length < 12) {
    return false;
  }
  return true;
};

export const isValidNumberInPassword = (password) => {
  // Contains at least 1 number
  if (!/\d/.test(password)) {
    return false;
  }
  return true;
};

export const isValidSpecialCharacterInPassword = (password) => {
  // Contains at least 1 special character
  if (!/[!@#$%^&*(),.?":{}|<>]/.test(password)) {
    return false;
  }
  return true;
};

export const isValidUppercaseInPassword = (password) => {
  // Contains at least 1 uppercase letter
  if (!/[A-Z]/.test(password)) {
    return false;
  }
  return true;
};

export const isValidLowercaseInPassword = (password) => {
  // Contains at least 1 lowercase letter
  if (!/[a-z]/.test(password)) {
    return false;
  }
  return true;
};

export const convertPhoneNumber = (value) => {
  // Remove any non-digit characters from the phone number
  var digitsOnly = value.replace(/\D/g, "");
  // Add the country code
  var convertedNumber = "+1" + digitsOnly;
  return convertedNumber;
};
