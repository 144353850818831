// import { useQueryParams } from "./hooks";

const prodEndpoint = "https://api.pocketbook.tech/dev";

// const devEndpoint = "http://localhost:8002/localdev";

// const reactDevEndpoint = "http://localhost:3000";
const reactProdEndpoint = "https://signup.pocketbook.tech";

const apiEndpoint = prodEndpoint;
const reactEndpoint = reactProdEndpoint;

export { apiEndpoint, reactEndpoint };

const connectQuickBook = async (api_key, cognito_id) => {
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "x-api-key": api_key,
    },
    body: JSON.stringify({
      cognito_id: cognito_id,
      redirect_uri: `${reactEndpoint}/integrations/quickbooks/validate`,
    }),
  };
  try {
    const response = await fetch(
      `${apiEndpoint}/v0/integrations/new_qb_integration`,
      options
    );
    const res = await response.json();
    window.location.href = res.redirect_uri;

    return res;
  } catch (err) {
    console.log(err);
  }
};

const connectXero = async (api_key, cognito_id) => {
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "x-api-key": api_key,
    },
    body: JSON.stringify({
      cognito_id: cognito_id,
      redirect_uri: `${reactEndpoint}/integrations/xero/validate`,
    }),
  };
  try {
    const response = await fetch(
      `${apiEndpoint}/v0/integrations/new_xero_integration`,
      options
    );
    const res = await response.json();
    if (res.redirect_uri) {
      window.location.href = res.redirect_uri;
    }
    return res;
  } catch (err) {
    console.log(err);
  }
};

const confirmQBIntegration = async (
  apiKey,
  cognito_id,
  integration_id,
  code,
  realm_id,
  redirect_uri,
  state
) => {
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "x-api-key": apiKey,
    },
    body: JSON.stringify({
      cognito_id: cognito_id,
      integration_id: integration_id,
      code: code,
      realm_id: realm_id,
      redirect_uri: redirect_uri,
      state: state,
    }),
  };
  const response = await fetch(
    `${apiEndpoint}/v0/integrations/confirm_qb_integration`,
    options
  );
  const res = await response.json();
  return res;
};

const confirmXeroIntegration = async (
  apiKey,
  cognito_id,
  integration_id,
  code,
  state,
  scope,
  session_state,
  redirect_uri
) => {
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "x-api-key": apiKey,
    },
    body: JSON.stringify({
      cognito_id: cognito_id,
      integration_id: integration_id,
      code: code,
      state: state,
      scope: scope,
      session_state: session_state,
      redirect_uri: redirect_uri,
    }),
  };
  const response = await fetch(
    `${apiEndpoint}/v0/integrations/confirm_xero_integration`,
    options
  );
  const res = await response.json();
  return res;
};

const validateIntegration = async (integrationId, userInfo, queryParams) => {
  if (integrationId === "quickbooks") {
    const { apiKey, cognitoId } = userInfo;
    const { code, realmId, state } = queryParams;
    try {
      let res = await confirmQBIntegration(
        apiKey,
        cognitoId,
        integrationId.toUpperCase(),
        code,
        realmId,
        `${reactEndpoint}/integrations/quickbooks/validate`,
        state
      );
      console.log(res);
      return res;
    } catch (e) {
      console.log(e);
    }
  }

  if (integrationId === "xero") {
    const { apiKey, cognitoId } = userInfo;
    const { code, state, scope, session_state } = queryParams;
    try {
      // apiKey, cognito_id, integration_id, code, state, scope, session_state, redirect_uri
      let res = await confirmXeroIntegration(
        apiKey,
        cognitoId,
        integrationId.toUpperCase(),
        code,
        state,
        scope,
        session_state,
        `${reactEndpoint}/integrations/xero/validate`
      );
      return res;
    } catch (e) {
      console.log(e);
    }
  }
};

const revokeIntegration = async (integrationId, queryParams) => {
  if (integrationId === "quickbooks") {
    const { cognitoId, apiKey } = queryParams;
    try {
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": apiKey,
        },
        body: JSON.stringify({
          cognito_id: cognitoId,
          integration_name: integrationId.toUpperCase(),
        }),
      };
      const response = await fetch(
        `${apiEndpoint}/v0/integrations/revoke_qb_integration`,
        options
      );
      const res = await response.json();
      return res;
    } catch (e) {
      console.log(e);
    }
  }
};

export {
  connectQuickBook,
  connectXero,
  validateIntegration,
  revokeIntegration,
};
