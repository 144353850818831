import React from 'react'
import { OnboardingLayout } from './components'
import { ContactFooter } from './components'
import styled from 'styled-components';

export const Success=()=>{
  return (
    <OnboardingLayout footer={<ContactFooter/>}>
        <Title>
        Welcome! Your Pocketbook account has been created. Check your email to get started.
        </Title>
        <Description>
        You can close this window.
        </Description>
        <Description>
        Didn’t receive an email? <a href='#'>Click to resend.</a>
        </Description>
    </OnboardingLayout>
  )
}

const Title=styled.div`
font-family: "Inter";
  font-size: 36px;
  font-weight: 590;
  line-height: normal;
  display: flex;
  align-items: center;

  @media (max-width: 1000px) {
    font-size: 30px;
  }

  @media (max-width: 800px) {
    font-size: 24px;
  }
`

const Description=styled.div`
    font-family: 'Inter';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    margin-top: 30px;
`