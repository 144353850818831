import React from "react";
import {
  SignupWrapper,
  LeftSection,
  SignupLeftHeader,
  PocketbookLogo,
} from "../styled";
import { navigate } from "@reach/router";
import logo from "assets/images/pocketbook-logo.png";
import signup_logo from "assets/images/sign-up-bg.png";
import { rootRoute } from "routes";
import styled from "styled-components";

export const OnboardingLayout = ({
  rightImage = signup_logo,
  footer,
  isUrlPage,
  children,
}) => {
  return (
    <SignupWrapper>
      <LeftSection>
        <SignupLeftHeader>
          <PocketbookLogo
            src={logo}
            alt="logo"
            onClick={() => navigate(rootRoute)}
          />
        </SignupLeftHeader>
        <SignupFormSection>
          <div>{children}</div>
        </SignupFormSection>
        {footer}
      </LeftSection>
      <ChampionBgSection $isUrlPage={isUrlPage}>
        <SignupRightImage
          src={rightImage}
          alt="signup-right-image"
          $isUrlPage={isUrlPage}
        />
      </ChampionBgSection>
    </SignupWrapper>
  );
};

export const SignupFormSection = styled.div`
  max-width: 540px;
  padding: 0 42px 42px 42px;
  flex-grow: 1;
  display: flex;
  align-items: center;
`;

const SignupRightImage = styled.img`
  width: ${(props) => (props.$isUrlPage ? "70%" : "100%")};
  max-width: 750px;
  height: auto;
`;

const ChampionBgSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: ${(props) => (props.$isUrlPage ? "flex-end" : "center")};
  flex-basis: 50%;
  background: #f8f8f8;
  position: relative;
  overflow: hidden;

  @media (max-width: 600px) {
    display: none;
  }
`;
