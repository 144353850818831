import React from "react";
import { Router } from "@reach/router";
// import SideBar from "./components/sidebar";
// import ProtectedRoute from "./helpers/protectedRouter";
import GenericLogin from "pages/login/generic-login";
import Plan from "./pages/plan/plan";
import SignupChallenger from "./pages/signup/signup-challenger";
import {
  SignupChampion,
  SubscriptionPlan,
  ConnectBank,
  ChosenPlanConfirm,
  PersonalUrl,
  Success,
} from "./pages/signup";
import { rootRoute, onboardingRoutes } from "./routes";
import Integrations from "pages/integrations/integrations";
import ValidateIntegration from "pages/integrations/validate";
import ForgotPassword from "pages/signup/forgot-password";
import EmailConfirm from "./pages/signup/confirm";
import RevokeIntegration from "pages/integrations/revoke";
import { ToastContainer } from "react-toastify";

const App = () => {
  return (
    <div>
      <Router>
        <Plan path={rootRoute} />
        <SignupChampion path={onboardingRoutes.signUp} />
        <SubscriptionPlan path={onboardingRoutes.subscription} />
        <ConnectBank path={onboardingRoutes.connectBank} />
        <ChosenPlanConfirm path={onboardingRoutes.confirmPlan} />
        <PersonalUrl path={onboardingRoutes.personalUrl} />
        <Success path={onboardingRoutes.success} />
        <EmailConfirm path="confirm" />
        <GenericLogin path="login" />
        <ForgotPassword path="forgot-password" />
        <Integrations path="integrations/:integrationId" />
        <ValidateIntegration path="integrations/:integrationId/validate" />
        <RevokeIntegration path="integrations/:integrationId/revoke" />
      </Router>
      <ToastContainer />
    </div>
  );
};

export default App;
