import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { navigate } from "@reach/router";
import { Checkbox, CircularProgress} from "@mui/material";
import {actionTypes} from "../../redux/actionTypes";
import logo from "assets/images/pocketbook-logo.png";
import signup_logo from "assets/images/sign-up-bg.png";
import styled from "styled-components";
import { convertPhoneNumber } from "scripts/functions";
import { StyledPhoneField, StyledTextField } from "./utils/styled-inputfield";
import {
  PocketbookLogo,
  ChampionBgSection,
  BottomButton,
  FormGroupSection,
  LeftSection,
  SignupFormDescription,
  SignupFormSection,
  SignupFormTitle,
  SignupRightImage,
  SignupWrapper,
  SignupLeftHeader,
  SignupLeftFooter,
  TermsText,
  BottomButtonsWrapper,
  PasswordsWrapper
} from "./styled";
import {
  isValidLowercaseInPassword,
  isValidNumberInPassword,
  isValidPasswordLength,
  isValidSpecialCharacterInPassword,
  isValidUppercaseInPassword,
} from "scripts/functions";
import { onboardingRoutes } from "routes";
import { BsFillEyeSlashFill, BsFillEyeFill } from "react-icons/bs";
import {IconButton} from "@mui/material"
import { createUserId } from "./utils";
import { Button } from "ui";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const SignupChampion = () => {
  const dispatch = useDispatch();
  const { profileInfo } = useSelector((state) => state.profile);
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword]=useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [termChecked, setTermChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [validated, setValidated] = useState({
    name: null,
    phone: null,
    email: null,
    password: null,
    confirmPassword: null
  });
  const [showPassword, setShowPassword]=useState(false)
  const [showConfirmPassword, setShowConfirmPassword]=useState(false)
  const [errMessage, setErrMessage] = useState({
    name: "Business Name is required!",
    phone: "Business Phone is required!",
    email: "Business Email is required!",
    confirmPassword: "Password does not match. Try again."
  });

  const validatePassword = (value) => {
    if (!isValidPasswordLength(value)) {
      setValidated((prevState) => ({ ...prevState, password: false }));
    } else if (!isValidNumberInPassword(value)) {
      setValidated((prevState) => ({ ...prevState, password: false }));
    } else if (!isValidSpecialCharacterInPassword(value)) {
      setValidated((prevState) => ({ ...prevState, password: false }));
    } else if (!isValidUppercaseInPassword(value)) {
      setValidated((prevState) => ({ ...prevState, password: false }));
    } else if (!isValidLowercaseInPassword(value)) {
      setValidated((prevState) => ({ ...prevState, password: false }));
    } else {
      setValidated((prevState) => ({ ...prevState, password: true }));
    }

    if (confirmPassword !== "") {
      if (value === confirmPassword) {
        setValidated((prevState) => ({ ...prevState, confirmPassword: true }));
      } else {
        setValidated((prevState) => ({ ...prevState, confirmPassword: false }));
        setErrMessage((prevState) => ({
          ...prevState,
          confirmPassword: "Does not match. Try again",
        }));
      }
    }
  };

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleClickShowConfirmPassword = () => setShowConfirmPassword(!showConfirmPassword);

  const validateConfirmPassword = (value) => {
    if (value === "") {
      setErrMessage((prevState) => ({
        ...prevState,
        confirmPassword: "Confirm Password is required!",
      }));
      setValidated((prevState) => ({ ...prevState, confirmPassword: false }));
    } else if (value !== password) {
      setErrMessage((prevState) => ({
        ...prevState,
        confirmPassword: "Does not match. Try again",
      }));
      setValidated((prevState) => ({ ...prevState, confirmPassword: false }));
    } else {
      setValidated((prevState) => ({ ...prevState, confirmPassword: true }));
    }
  };

  const handlePasswordChange = (event) => {
    setIsSubmitted(false);
    validatePassword(event.target.value);
    setPassword(event.target.value);
  };

  const handleConfirmPasswordChange = (event) => {
    setIsSubmitted(false);
    validateConfirmPassword(event.target.value);
    setConfirmPassword(event.target.value);
  };

  useEffect(() => {
    if (profileInfo.name) {
      setName(profileInfo.name);
      validateName(profileInfo.name);
    }
    if (profileInfo.phone) {
      setPhone(profileInfo.phone);
      validatePhone(profileInfo.phone);
    }
    if (profileInfo.email) {
      setEmail(profileInfo.email);
      validateEmail(profileInfo.email);
    }
  }, [
    profileInfo.email,
    profileInfo.name,
    profileInfo.phone,
  ]);


  const handleLinkTOS = () => {
    window.open(
      "https://cdn.pocketbook.tech/pb_tech/Pocketbook_Terms_of_Service.pdf",
      "_blank"
    );
  };

  const handleLinkPrivacyPolicy = () => {
    window.open(
      "https://cdn.pocketbook.tech/pb_tech/Pocketbook_Privacy_Policy.pdf",
      "_blank"
    );
  };

  const validateName = (value) => {
    if (value === "") {
      setValidated((prevState) => ({ ...prevState, name: false }));
      setErrMessage((prevState) => ({
        ...prevState,
        name: "Business Name is required!",
      }));
    } else {
      setValidated((prevState) => ({ ...prevState, name: true }));
    }
  };

  const validatePhone = (value) => {
    var phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    const numValidation = value.match(phoneno);
    if (value === "") {
      setErrMessage((prevState) => ({
        ...prevState,
        phone: "Business Phone is required!",
      }));
      setValidated((prevState) => ({ ...prevState, phone: false }));
    } else if (!numValidation) {
      setErrMessage((prevState) => ({
        ...prevState,
        phone: "Business Phone must be valid",
      }));
      setValidated((prevState) => ({ ...prevState, phone: false }));
    } else {
      setValidated((prevState) => ({ ...prevState, phone: true }));
    }
  };

  const validateEmail = (value) => {
    const validEmailRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (value === "") {
      setValidated((prevState) => ({ ...prevState, email: false }));
      setErrMessage((prevState) => ({
        ...prevState,
        email: "Business Email is required!",
      }));
    } else if (!validEmailRegex.test(value)) {
      setValidated((prevState) => ({ ...prevState, email: false }));
      setErrMessage((prevState) => ({
        ...prevState,
        email: "Business Email must be valid",
      }));
    } else {
      setValidated((prevState) => ({ ...prevState, email: true }));
    }
  };

  const handleNameChange = (event) => {
    setIsSubmitted(false);
    validateName(event.target.value);
    setName(event.target.value);
  };

  const handlePhoneChange = (event) => {
    setIsSubmitted(false);
    validatePhone(event.target.value);
    setPhone(event.target.value);
  };

  const handleEmailChange = (event) => {
    setIsSubmitted(false);
    validateEmail(event.target.value);
    setEmail(event.target.value);
  };

  const handleTermChange = (event) => {
    setTermChecked(event.target.checked);
  };

  const onSubmit = async () => {
    if(!termChecked){
      toast.error("Please acknowledge Pocketbook’s Terms of Service and Privacy Policy")
    }
    else {
      setIsSubmitted(true);
      setValidated({
        name: validated.name === null ? false : validated.name,
        phone: validated.phone === null ? false : validated.phone,
        email: validated.email === null ? false : validated.email,
        password: validated.password === null ? false : validated.password,
        confirmPassword: validated.confirmPassword === null ? false : validated.confirmPassword,
      });

      setLoading(true);
    
      };

      if (
        validated.name &&
        validated.phone &&
        validated.email &&
        validated.password &&
        validated.confirmPassword &&
        termChecked
      ) {
        let payload={
          business_name: name,
          business_phone: convertPhoneNumber(phone),
          business_email: email,
          password: password,
          accepted_pb_tos_pp: termChecked,
          user_type: "client"
        }
        try {
          setLoading(true);
        
          const response = await createUserId(payload);
        
          dispatch({
            type: actionTypes.PROFILE_INFO,
            payload: { ...payload, user_id: response.user_id }
          });
        
          navigate(onboardingRoutes.subscription);
        } catch (error) {
          toast.error(error.message, {
            position: toast.POSITION.BOTTOM_CENTER
          });
          console.error(error.message);
        } finally {
          setLoading(false);
        }
      }
    };

  return (
    <SignupWrapper>
      <LeftSection>
        <SignupLeftHeader>
          <PocketbookLogo src={logo} alt="logo" onClick={() => navigate("/")} />
        </SignupLeftHeader>
        <SignupFormSection>
            <SignupFormTitle>Save money.</SignupFormTitle>
            <SignupFormTitle>Run your business.</SignupFormTitle>
          <SignupFormDescription>
            Start an account, 14-day free trial.
          </SignupFormDescription>
          <FormGroupSection className="signup-form">
            <StyledTextField
              name="businessName"
              value={name}
              placeholder="Business Name"
              validation={validated.name}
              errMsg={errMessage.name}
              isSubmitted={isSubmitted}
              handleChange={handleNameChange}
            />
            <StyledPhoneField
              name="phoneNumber"
              value={phone}
              placeholder="Business Phone"
              validation={validated.phone}
              errMsg={errMessage.phone}
              isSubmitted={isSubmitted}
              handleChange={handlePhoneChange}
            />
            <StyledTextField
              name="businessEmail"
              value={email}
              placeholder="Business Email"
              validation={validated.email}
              errMsg={errMessage.email}
              isSubmitted={isSubmitted}
              handleChange={handleEmailChange}
              autocomplete={false}
            />
        <PasswordsWrapper>
              <PasswordWrapper>
              <StyledTextField
                type={showPassword?"text":"password"}
                name="password"
                value={password}
                placeholder="Set your password"
                validation={validated.password}
                errMsg={errMessage.password}
                isSubmitted={isSubmitted}
                handleChange={handlePasswordChange}
              />
              <StyledIconButton onClick={handleClickShowPassword}>
                {showPassword ? (
                  <BsFillEyeFill />
                ) : (
                  <BsFillEyeSlashFill />
                )}
              </StyledIconButton>
              </PasswordWrapper>
              <PasswordWrapper>
              <StyledTextField
                type={showConfirmPassword?"text":"password"}
                name="confirmPassword"
                value={confirmPassword}
                placeholder="Confirm you password"
                validation={validated.confirmPassword}
                errMsg={errMessage.confirmPassword}
                isSubmitted={isSubmitted}
                handleChange={handleConfirmPasswordChange}
              />
              <StyledIconButton onClick={handleClickShowConfirmPassword}>
                {showConfirmPassword ? (
                  <BsFillEyeFill />
                ) : (
                  <BsFillEyeSlashFill />
                )}
              </StyledIconButton>
              </PasswordWrapper>
        </PasswordsWrapper>
        {password.length>0 &&
        <PasswordErrorsWrapper>
          <PasswordError $isValid={isValidPasswordLength(password)}>Minimum length of 12 characters</PasswordError>
          <PasswordError $isValid={isValidLowercaseInPassword(password)}>Must contain a lowercase letter</PasswordError>
          <PasswordError $isValid={isValidUppercaseInPassword(password)}>Must contain an uppercase letter</PasswordError>
          <PasswordError $isValid={isValidNumberInPassword(password)}>Must contain a number</PasswordError>
          <PasswordError $isValid={isValidSpecialCharacterInPassword(password)}>Must contain a special character</PasswordError>
        </PasswordErrorsWrapper>
        }
            <div className="flex align-items-center">
              <Checkbox
                checked={termChecked}
                onChange={handleTermChange}
                inputProps={{ "aria-label": "controlled" }}
                style={{ padding:0, paddingRight: 8 }}
              />
              <TermsText style={{ color: termChecked ? "#000000" : "#939393" }}>
                I agree to Pocketbook's{" "}
                <span
                  className="text-underline cursor-pointer"
                  onClick={handleLinkTOS}
                >
                  Terms of Service
                </span>{" "}
                and{" "}
                <span
                  className="text-underline cursor-pointer"
                  onClick={handleLinkPrivacyPolicy}
                >
                  Privacy Policy
                </span>
              </TermsText>
            </div>
            <Button variant="black" marginTop={40} onClick={onSubmit}>
              Create account{" "}
              {loading && (
                <CircularProgress
                  color="inherit"
                  size={14}
                  style={{ marginLeft: 10 }}
                />
              )}</Button>
          </FormGroupSection>
        </SignupFormSection>
        <SignupLeftFooter>
          <BottomButtonsWrapper>
            <BottomButton>
              Questions? Feel free to{" "}
              <a
                href="mailto:support@pocketbook.tech"
                style={{ color: "#939393" }}
              >
                contact our team.
              </a>
            </BottomButton>
            <BottomButton>
              Have a Pocketbook account?{" "}
              <span
                className="text-underline cursor-pointer"
                onClick={() => navigate("/login")}
              >
                Log in
              </span>
            </BottomButton>
          </BottomButtonsWrapper>
        </SignupLeftFooter>
      </LeftSection>
      <ChampionBgSection>
        <SignupRightImage src={signup_logo} alt="signup-right-image" />
      </ChampionBgSection>
    </SignupWrapper>
  );
};

const StyledIconButton=styled(IconButton)`
align-self: flex-start;
`

const PasswordErrorsWrapper=styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  margin-bottom: 19px;
`

const PasswordError=styled.li`
font-family: 'Inter';
color: ${(props) => (props.$isValid ? "#939393" : '#F15A25')};
font-size: 14px;
font-style: normal;
font-weight: 400;
`

const PasswordWrapper=styled.div`
  display: flex;
`