import { useEffect, useState } from "react";
import { useQueryParams, useLocalStorage } from "./hooks";
import { revokeIntegration, validateIntegration } from "./funcs";

const RevokeIntegration = (props) => {
  const { integrationId } = props;

  const queryParams = useQueryParams({ integrationId, read: true });
  const [revokeResponse, setRevokeResponse] = useState();

  const handleValidation = async () => {
    const res = await revokeIntegration(integrationId, queryParams);
    setRevokeResponse(res);
  };

  useEffect(() => {
    if (revokeResponse?.message) {
      window.location.href = `${queryParams.REDIRECT_URI}/integrations?integrationId=${integrationId}&status=revoked`;
    }
  }, [revokeResponse]);

  // return (
  //     <div onClick={() => console.log(validationRes)}>Redirecting to pocketbook platform</div>
  // )
  useEffect(() => {
    if (integrationId) {
      handleValidation();
    }
  }, [integrationId]);

  return (
    <div onClick={() => console.log(queryParams, integrationId)}>
      Redirecting to pocketbook platform
    </div>
  );
};

export default RevokeIntegration;
