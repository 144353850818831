import styled from "styled-components";

const IntegrationsWrapper = styled.div`
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
  @media (max-width: 600px) {
    display: block;
  }
`;

const IntegrationsHeader = ({ className, integration }) => {
  integration = integration[0].toUpperCase() + integration.slice(1);
  return <div className={className}>Redirecting to {integration}...</div>;
};

const IntegrationsHeaderStyled = styled(IntegrationsHeader)`
  padding: 50px 60px;
`;

export { IntegrationsWrapper, IntegrationsHeaderStyled as IntegrationsHeader };
