import styled from "styled-components";

export const PocketbookLogo = styled.img`
  width: 156px;
  height: 26px;
`;

export const SignupWrapper = styled.div`
  display: flex;
  height: 100vh;

  @media (max-width: 600px) {
    display: block;
  }
`;

export const ChampionBgSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-basis: 50%;
  background: #f8f8f8;
  position: relative;
  overflow: hidden;

  @media (max-width: 600px) {
    display: none;
  }
`;

export const RightSectionTitle = styled.div`
  position: absolute;
  left: 70px;
  bottom: 45px;
  font-family: Inter;
  font-weight: 590;
  font-size: 28px;
  line-height: 38px;
  color: #000000;
  width: 100%;
  max-width: 400px;
`;

export const LeftSection = styled.div`
  background-color: #ffffff;
  flex-basis: 50%;
  position: relative;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 600px) {
    display: block;
    height: 100%;
    flex-basis: 100%;
    padding: 20px;
  }
`;

export const SignupRightImage = styled.img`
  width: 100%;
  max-width: 750px;
  height: auto;
`;

export const SignupLeftHeader = styled.div`
  padding: 50px 60px;
  align-self: self-start;
  display: flex;
  justify-content: center;

  @media (min-width: 600px){ 
    display: block;
  }
`;

export const SignupLeftFooter = styled.div`
  padding: 0px 60px;
  align-self: self-start;

  @media (min-width: 600px){ 
    padding: 50px 60px;
  }
`;

export const SignupFormSection = styled.div`
  max-width: 540px;
  padding: 0 42px 42px 42px;
`;

export const ForgotFormSection = styled.div`
  margin-left: 56px;
  margin-right: 28px;
  padding: 50px 87px 35px 50px;
  max-width: 600px;

  @media (max-width: 1000px) {
    padding: 30px 50px 20px 20px;
  }

  @media (max-width: 600px) {
    margin-left: 28px;
    padding: 30px 50px 20px 20px;
  }
`;

export const SignupFormTitle = styled.div`
  font-family: "Inter";
  font-size: 48px;
  font-weight: 590;
  line-height: normal;
  display: flex;
  align-items: center;

  @media (max-width: 1000px) {
    font-size: 30px;
  }

  @media (max-width: 800px) {
    font-size: 24px;
  }
`;

export const SignupFormDescription = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 590;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  padding-top: 22px;

  @media (max-width: 500px) {
    font-size: 14px;
    font-weight: 400;
  }
`;

export const SetPasswordBackBtn = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  padding-bottom: 22px;
  cursor: pointer;
  display: flex;
  align-items: center;
`;

export const FormGroupSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  padding: 0px;
  margin-top: 50px;
`;

export const FormSubmitBtn = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 590;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #ffffff;
  padding: 12px;
  border-radius: 12px;
  background-color: #000000;
  cursor: pointer;
  width: fit-content;
  margin-top: 40px;
  display: flex;
  align-items: center;
`;

export const BottomButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

export const BottomButton = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  cursor: pointer;
  color: #939393;

  @media (max-width: 600px) {
    position: initial;
    margin-top: 30px;
  }
`;

export const ErrMsg = styled.div`
  color: #f15a25;
  font-weight: 400;
  font-size: 14px;
`;

export const ConfirmWrapper = styled.div`
  width: 100%;
  max-width: 640px;
  padding-top: 120px;
  padding-bottom: 80px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;

  @media (max-width: 700px) {
    padding-top: 80px;
    padding-left: 20px;
    padding-right: 20px;
  }

  @media (max-width: 500px) {
    padding-top: 40px;
  }
`;

export const ConfirmPocketLogo = styled.img`
  max-width: 350px;
  width: 100%;
  height: auto;
  margin-bottom: 30px;
`;

export const ConfirmMainSection = styled.div`
  padding: 32px;
  background-color: #ffffff;
  @media (max-width: 500px) {
    padding: 0px;
  }
`;

export const ConfirmMainImg = styled.img`
  width: 100%;
  max-width: 640px;
  height: auto;
`;

export const ConfirmText = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #000000;
  padding-bottom: 24px;
`;

export const ConfirmBtn = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 590;
  font-size: 16px;
  line-height: 17px;
  text-align: center;
  color: #ffffff;
  padding: 12px;
  border-radius: 12px;
  background-color: #000000;
  cursor: pointer;
  width: fit-content;
`;

export const ConfirmDescription = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #939393;
  text-align: start;
  padding: 0px 24px;
`;

export const ConfirmFooter = styled.div`
  margin-top: 60px;
  text-align: center;
`;

export const ConfirmFooterDescription = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  padding-top: 30px;
`;

export const SignupSubmitSection = styled.div`
  margin-left: 56px;
  margin-right: 28px;
  padding: 50px 100px 50px 50px;
  max-width: 600px;
  width: 100%;
  display: flex;
  align-items: center;
  height: calc(100% - 250px);

  @media (max-width: 1000px) {
    padding: 30px 50px 30px 20px;
  }

  @media (max-width: 600px) {
    margin-left: 28px;
    padding: 30px 50px 30px 20px;
  }
`;

export const EmailConfirmSection = styled.div`
  margin-left: 56px;
  margin-right: 28px;
  padding: 50px;
  max-width: 600px;
  width: 100%;
  display: flex;
  align-items: center;
  height: calc(100% - 250px);

  @media (max-width: 1000px) {
    padding: 30px 50px 30px 20px;
  }

  @media (max-width: 600px) {
    margin-left: 28px;
    padding: 30px 50px 30px 20px;
  }
`;

export const TermsText = styled.div`
  color: #939393;
  font-family: Inter;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
`;

export const CheckedTermsText = styled.div`
  color: #000000;
  font-family: Inter;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
`;

export const SignupInputDescription = styled.div`
  color: #939393;
  font-family: Inter;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  padding-bottom: 40px;
`;

export const ChecklistLabel = styled.div`
  color: #939393;
  font-family: Inter;
  font-weight: 400;
  font-size: 14px;
  margin-left: 10px;
`;

export const ChecklistSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const PopoverSection = styled.div`
  padding: 14px;
  color: #000000;
  font-family: Inter;
  font-weight: 400;
  font-size: 14px;
`;

export const PasswordsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const ConfirmTitle = styled.div`
  font-size: 32px;
  font-weight: 590;
  font-family: Inter;
  line-height: 38px;
  font-style: normal;
  display: flex;
  align-items: center;

  @media (max-width: 1000px) {
    font-size: 26px;
  }

  @media (max-width: 800px) {
    font-size: 22px;
  }
`;
